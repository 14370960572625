import React from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShowNElements = (props) => {
    return(
        <div className="shownelements">
            <div className="shownelements__title" onClick={props.handleOpenShowNElementsModal} onKeyDown={props.handleOpenShowNElementsModal} tabIndex="0">
                {i18next.t("PAGINATION")}
                <FontAwesomeIcon className="shownelements__title-icon" icon="angle-down" />
            </div>
            <div className="shownelements__fade" onClick={props.handleOpenShowNElementsModal}></div>
            <ul className="shownelements__options">
                <li className="shownelements__option">
                    <label className="active">
                        <input type="radio" name="shownelements" value="12" onClick={props.handleShowNElementsChange} defaultChecked />
                        {"12 " + i18next.t("PAGINATION_LABEL")}
                    </label>
                </li>
                <li className="shownelements__option">
                    <label>
                        <input type="radio" name="shownelements" value="24" onClick={props.handleShowNElementsChange} />
                        {"24 " + i18next.t("PAGINATION_LABEL")}
                    </label>
                </li>
                <li className="shownelements__option">
                    <label>
                        <input type="radio" name="shownelements" value="48" onClick={props.handleShowNElementsChange} />
                        {"48 " + i18next.t("PAGINATION_LABEL")}
                    </label>
                </li>
                <li className="shownelements__option">
                    <label>
                        <input type="radio" name="shownelements" value="0" onClick={props.handleShowNElementsChange} />
                        {i18next.t("PAGINATION_LABEL_ALL")}
                    </label>
                </li>
            </ul>
        </div>
    );
}

export default ShowNElements;