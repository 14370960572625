import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AccordionMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed: true
        }
    }

    /**
     * @function handleAccordionMenu
     * @desc this function open or close the accordion menu
     */
    handleAccordionMenu = () => {
        this.setState({
            isClosed: !this.state.isClosed
        });
    }

    render(){
        return (
            <div className={this.state.isClosed ? "accordion-menu closed" :"accordion-menu"}>
                <div 
                    className="accordion-menu__title"
                    onClick={this.handleAccordionMenu} 
                    onKeyDown={this.handleAccordionMenu}>
                    {this.props.title}
                    <FontAwesomeIcon className="accordion-menu__icon" icon={this.state.isClosed ? 'plus' : 'minus'} />
                </div>
                <div className="accordion-menu__content">
                    {this.props.content}
                </div>
            </div>
        )
    }
}

export default AccordionMenu;