import React from 'react';
import Layout from "../layouts/index";
import '../style/index.scss';
import { graphql, Link } from 'gatsby';
import SortBy from '../components/sortby';
import ShowNElements from '../components/shownelements';
import ProductTile from '../components/productTile';
import Filter from '../components/filter';
import i18next from 'i18next';
import SEO from '../components/seo'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import AccordionMenu from '../components/accordion_menu';
import Carousel from "react-slick";


let previousProduct = '';

const catLinksSettings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
};

class CategoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productArray: this.resetProductArray(),
            pageNumber: 1,
            pageSize: 12,
            paginationSlices: [],
            tilesTotal: 0,
            showedProducts: [],
            showFilter: true,
            showShowNElements: true
        }
    }

    componentDidMount() {
        if (this.props.data.category.products.length < 2) {
            this.setState({
                showFilter: false
            })
        }
        if (this.props.data.category.products.length < 13) {
            this.setState({
                showShowNElements: false
            })
        }
        //Check initial parameter "page"
        const page = new URLSearchParams(document.location.search.substring(1)).get("page");
        if (page !== null && !isNaN(page)) {
            this.setState({
                pageNumber: parseInt(page),
                showedProducts: []
            }, this.setShowedProductsOnScroll);
        } else {
            this.setShowedProductsOnScroll();
        }
        this.setPagesMap();
    }

    /**
     * @function resetProductArray
     * @desc this function rest the product array into the original product array 
     * 
     * @param {e}
     */
    resetProductArray = () => {
        return this.props.data.category.products.sort((a, b) => {
            if(a.brand === b.brand) {
                return (a.modelo < b.modelo) ? -1 : (a.modelo > b.modelo) ? 1 : 0;
            } else {
                return (a.brand < b.brand) ? -1 : 1;
            }
        });
    }

    /**
     * @function handleSortByChange
     * @desc this function sort the product array by the selected option
     * 
     * @param {e}
     */
    handleSortByChange = (e) => {
        switch (e.target.value) {
            case "name-asc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => {
                        return (a.descripcion < b.descripcion) ? -1 : 1;
                    })
                });
                break;
            case "name-desc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => {
                        return (a.descripcion > b.descripcion) ? -1 : 1;
                    })
                });
                break;
            case "price-asc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => (a.offerpriceeur > b.offerpriceeur) ? 1 : -1)
                });
                break;
            case "price-desc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => (a.offerpriceeur < b.offerpriceeur) ? 1 : -1)
                });
                break;
            default:
                break;
    
        }
        this.setState({
            pageNumber: 1,
            showedProducts: []
        }, this.setShowedProductsOnScroll);
        if (document.querySelector(".sort-by label.active")) document.querySelector(".sort-by label.active").classList.remove('active');
        e.target.parentNode.classList.add('active');
    }

    /**
     * @function handleShowNElementsChange
     * @desc this function set the page elements by the selected option
     * 
     * @param {e}
     */
    handleShowNElementsChange = (e) => {
        let pageElements = parseInt(e.target.value);
        //TODO Improve this check
        if (pageElements == 0) {
            pageElements = this.state.tilesTotal;
        }
        this.setState({
            pageNumber: 1,
            pageSize: pageElements,
            showedProducts: []
        }, this.setShowedProductsOnScroll);
        document.querySelector(".shownelements label.active").classList.remove('active');
        e.target.parentNode.classList.add('active');
    }

    /**
     * @function handleOpenSortModal
     * @desc this function open or close the sort-by modal
     */
    handleOpenSortModal = () => {
        if (document.querySelector('.filter__title')) {
            document.querySelector('.filter__title').classList.remove('opend');
            document.querySelector('.filter__title').parentElement.classList.remove('opend');
        }
        if (document.querySelector('.shownelements__title')) {
            document.querySelector('.shownelements__title').classList.remove('opend');
            document.querySelector('.shownelements__title').parentElement.classList.remove('opend');
        }
        document.querySelector('.sort-by__title').classList.toggle('opend');
        document.querySelector('.sort-by__title').parentElement.classList.toggle('opend');
    }

    /**
     * @function handleOpenShowNElementsModal
     * @desc this function open or close the shownelements modal
     */
    handleOpenShowNElementsModal = () => {
        if (document.querySelector('.filter__title')) {
            document.querySelector('.filter__title').classList.remove('opend');
            document.querySelector('.filter__title').parentElement.classList.remove('opend');
        }
        if (document.querySelector('.sort-by__title')) {
            document.querySelector('.sort-by__title').classList.remove('opend');
            document.querySelector('.sort-by__title').parentElement.classList.remove('opend');
        }
        document.querySelector('.shownelements__title').classList.toggle('opend');
        document.querySelector('.shownelements__title').parentElement.classList.toggle('opend');
    }

    /**
     * @function handleSortByChange
     * @desc this function set the pagination of the product array
     * 
     * @param {e}
     */
    handleFilter = (prodArray) => {
        this.setState({
            productArray: prodArray,
            pageNumber: 1,
            showedProducts: []
        }, this.setShowedProductsOnScroll);        
    }

    /**
     * @function setShowedProductsOnScroll
     * @desc this function show more products when scroll
     */
    setShowedProductsOnScroll = () => {
        let pagesMap = this.setPagesMap();
        let slice = pagesMap[this.state.pageNumber-1];
        
        if (this.state.pageNumber === 1) {
            window.history.replaceState({}, document.title, document.location.origin + document.location.pathname);
        }

        this.setState({
            paginationSlices: [...pagesMap],
            showedProducts: [...this.state.productArray.slice(slice.init, slice.end)]
        });
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    /**
     * @function handlePagination
     * @desc this function move the pagination
     * 
     * @param {e}
     */
    handlePagination = (event, slice) => {
        this.setState({
            pageNumber: parseInt(slice),
            showedProducts: []
        }, this.setShowedProductsOnScroll);
    }

    /**
     * @function setPagesMap
     * @desc this function set the map of number of pages and slices
     */
    setPagesMap = () => {
        // Initial data: page 1, initial size of slice calculated, initial array initated
        let currentPage = 1;
        let initNumberProductToShow = this.state.pageSize * (currentPage - 1);
        let endNumberProductToShow = this.state.pageSize * currentPage;
        let page = {
            init : 0,
            end : 0,
        }
        let pageArray = [page]; 

        // Slice calculations
        if(this.state.productArray.length < this.state.pageSize) {
            // Page size less than total of products, array with one item
            pageArray[0].end = this.state.pageSize;
        } else if (this.state.pageSize === this.state.tilesTotal) {
            // Show all products, array with one item
            pageArray[0].end = this.state.productArray.length+1;
        } else {
            // Calculate array
            let countTiles = 0;
            for(var indexProduct = 1; indexProduct < this.state.productArray.length; indexProduct++) {
                if(countTiles === initNumberProductToShow) {
                    pageArray[currentPage-1].init = indexProduct - 1;
                }
                let previousProduct = this.state.productArray[indexProduct - 1];
                let currentProduct = this.state.productArray[indexProduct];
                if(previousProduct.marca !== currentProduct.marca || previousProduct.modelo !== currentProduct.modelo) {
                    countTiles++
                }
                if(countTiles === endNumberProductToShow) {
                    pageArray[currentPage-1].end = indexProduct;
                    let page = {
                        init : 0,
                        end : 0,
                    }
                    pageArray.push(page);
                    currentPage = currentPage + 1;
                    initNumberProductToShow = this.state.pageSize * (currentPage - 1);
                    endNumberProductToShow = this.state.pageSize * currentPage;
                }
            }
            // Re-check last slice values
            if (pageArray.length > 2 && pageArray[pageArray.length-1].init === 0) {
                pageArray[pageArray.length-1].init = pageArray[pageArray.length-2].end;
            }
            if (pageArray[pageArray.length-1].end === 0) {
                pageArray[pageArray.length-1].end = this.state.productArray.length+1;
            }
        }

        // Recalculate the total tiles
        let countTiles = 0;
        for(var indexProduct = 1; indexProduct < this.state.productArray.length; indexProduct++) {
            if(this.state.productArray[indexProduct - 1].marca !== this.state.productArray[indexProduct].marca ||
                this.state.productArray[indexProduct - 1].modelo !== this.state.productArray[indexProduct].modelo) {
                countTiles++
            }
        }
        this.setState({
            tilesTotal: (countTiles + 1)
        })

        // return the array
        return pageArray;
    }

    render() {
        const data = this.props.data;
        previousProduct = '';
        const {
            breadcrumb: { crumbs },
        } = this.props.pageContext;
        return(
            <Layout children={null} data={data}>
                <SEO 
                    title={data.category.name}
                    keywords=''
                    slug={typeof window !== `undefined` ? window.location.href.replace(window.location.origin,'').replace('/' + data.category.locale + '/','') : ''}
                    description={data.category.name}
                    lang={data.category.locale}
                    contactinfo={JSON.parse(data.contactinfo.data)}
                />
                <Breadcrumb crumbs={crumbs} crumbSeparator=" > " hiddenCrumbs={['/en', '/es']} crumbLabel={data.category.name} />
                <div className="plp">
                    <div className="plp__category-name">
                        <h1>{data.category.name}</h1>
                    </div>
                    <div className="plp__filters-sort">
                        {this.state.showFilter &&
                            <Filter productArray={this.state.productArray} handleFilter={this.handleFilter} locale={data.category.locale} newBadgeDays={data.newBadgeDays}></Filter>
                        }
                        <SortBy handleOpenSortModal={this.handleOpenSortModal} handleSortByChange={this.handleSortByChange}></SortBy>
                        {this.state.showShowNElements &&
                            <ShowNElements handleOpenShowNElementsModal={this.handleOpenShowNElementsModal} handleShowNElementsChange={this.handleShowNElementsChange}></ShowNElements>
                        }
                    </div>
                    <div className="plp__marketing-top">
                    {
                        this.state.productArray.length > 0 && data.category.marketingTop &&
                            <AccordionMenu 
                                title={i18next.t("PLP_INFO")}
                                content={data.category.marketingTop}
                            ></AccordionMenu>
                    }
                    </div>
                    {
                        data.category.toplinks && JSON.parse(data.category.toplinks).links && JSON.parse(data.category.toplinks).links.length > 0 &&
                        <div>
                            <div className="plp__categorieslinks forDesktop">
                                { 
                                    JSON.parse(data.category.toplinks).links.map((catLink, index) => {
                                        return (
                                            <Link key={"categoryLink" + index} className="link" to={catLink.link}>{catLink.text}</Link>
                                        )
                                    })
                                }
                            </div>
                            <div className="plp__categorieslinks forMobile">
                                <Carousel {...catLinksSettings}>
                                {
                                    JSON.parse(data.category.toplinks).links.map((catLink, index) => {
                                        return (
                                            <Link key={"categoryLink" + index} className="link" to={catLink.link}>{catLink.text}</Link>
                                        )
                                    })
                                }
                                </Carousel>
                            </div>
                        </div>
                    }
                    <div className="plp__wrapper">
                    {                
                        this.state.productArray.length > 0 ?
                            <div className="plp__category">
                                <div className="plp__grid">
                                {
                                    typeof this.state.showedProducts === 'undefined' ? '' :
                                    this.state.showedProducts.map((product, index) => {
                                        if(!previousProduct || product.marca !== previousProduct.marca || product.modelo !== previousProduct.modelo){
                                            previousProduct = product;
                                            return (
                                                <ProductTile key={"Product" + index} locale={data.category.locale} product={product} data={data}></ProductTile>
                                            )
                                        } else {
                                            return '';
                                        }
                                    })
                                }
                                </div>
                                <div className="plp__pagination">
                                    <div>
                                    {
                                        this.state.paginationSlices &&
                                        this.state.paginationSlices.map((slice, index) => {
                                            return (
                                                <Link key={"page" + index} to={"./" + (index > 0 ? "?page=" + (index+1) : "")} onClick={(event) => this.handlePagination(event, (index+1))} className={(index+1) === this.state.pageNumber ? 'active' : ''}>
                                                    {index+1}
                                                </Link>
                                            )
                                        })
                                    }
                                    </div>
                                    <div>
                                        <span>{i18next.t("PAGINATION_BOTTOM", { init:((this.state.pageSize * (this.state.pageNumber - 1)) + 1), end: (this.state.tilesTotal < this.state.pageSize * this.state.pageNumber ? this.state.tilesTotal : this.state.pageSize * this.state.pageNumber), total:this.state.tilesTotal})}</span>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="plp__not-found">
                                <span>{i18next.t("FILTER_NO_PRODUCTS_FOUND")}</span>
                            </div>
                    }
                    </div>
                    {
                        this.state.productArray.length > 0 && (data.category.marketingBottom || data.basemarketing.text) &&
                        <div className="plp__marketing-bottom">
                            <h3 className="plp__marketing-bottom-title">
                                <span>{data.category.name}</span>
                            </h3>
                            <div className="plp__marketing-bottom-wrapper">
                                <span className="plp__marketing-bottom-text">{data.category.marketingBottom ? data.category.marketingBottom : data.basemarketing.text}</span>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        )
    }
}

/* CategoryQuery */
export const query = graphql`
query ($categoryId: String!, $locale: String!) {
    category: datoCmsCategory(id: {eq: $categoryId }) {
      id
      name
      sequence
      locale
      url
      marketingTop
      marketingBottom
      toplinks
      products {
        id
        descripcion
        descripcionampliada
        categoria
        marca
        modelo
        colorcrist
        colormont
        forma
        material
        sexo
        polarizacion
        calibre
        puente
        varilla
        idarticulo
        images
        weight
        offerpriceeur
        offerpricegbp
        offerpriceusd
        listpriceeur
        listpricegbp
        listpriceusd
        crosssell
        meta {
          createdAt
        }
      }
      subcategory {
        id
        name
        url
        locale
        sequence
        products {
            id
            descripcion
            descripcionampliada
            categoria
            marca
            modelo
            colorcrist
            colormont
            forma
            material
            sexo
            polarizacion
            calibre
            puente
            varilla
            idarticulo
            images
            weight
            offerpriceeur
            offerpricegbp
            offerpriceusd
            listpriceeur
            listpricegbp
            listpriceusd
            crosssell
            meta {
              createdAt
            }
        }
        subcategory {
          id
          name
          url
          locale
          sequence
          products {
              id
              descripcion
              descripcionampliada
              categoria
              marca
              modelo
              colorcrist
              colormont
              forma
              material
              sexo
              polarizacion
              calibre
              puente
              varilla
              idarticulo
              images
              weight
              offerpriceeur
              offerpricegbp
              offerpriceusd
              listpriceeur
              listpricegbp
              listpriceusd
              crosssell
              meta {
                createdAt
              }
          }
        }
      }
    }
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
      edges {
        node {
          id
          name
          url
          sequence
          locale
          marketingTop
          marketingBottom
          subcategory {
            id
            name
            url
            locale
            sequence
            subcategory {
              id
              name
              url
              locale
              sequence
            }
          }
        }
      }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsProduct(filter: {locale: {eq: $locale}}, sort: {fields: modelo}) {
        edges {
            node {
                id
                descripcion
                descripcionampliada
                categoria
                categorias {
                    id
                    name
                    url
                    locale
                    sequence
                }
                marca
                modelo
                colorcrist
                colormont
                forma
                material
                sexo
                polarizacion
                calibre
                puente
                varilla
                idarticulo
                images
                weight
                offerpriceeur
                offerpricegbp
                offerpriceusd
                listpriceeur
                listpricegbp
                listpriceusd
                crosssell
                meta {
                  createdAt
                }
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
      edges {
        node {
            footerLinksList
            footerCopyright
            footerPayments
        }
      }
    }
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    basemarketing : datoCmsExtrainfo(name: {eq: "plp-basicdata"}, locale: {eq: $locale}) {
        text
    }
    newBadgeDays : datoCmsExtrainfo(name: {eq: "NewBadge_Constant"}, locale: {eq: $locale}) {
        data
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
  }
  `

export default CategoryPage